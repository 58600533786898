<template>
  <v-menu class="d-inline-block" offset-y>
    <template v-slot:activator="{ on }">
      <span
        id="thmenu-language-selector"
        class="language-select d-inline-flex align-items-center th-ml-12 cursor-pointer"
        v-on="on"
      >
        <img :src="flagImg(currentLanguage.lang)" :alt="currentLanguage.lang" />
        <p class="th-ml-8 d-flex align-self-center subtitle2">
          <span class="d-none d-lg-inline-block">
            {{ currentLanguage.region_language }}
          </span>
          <span class="d-lg-none">
            {{ currentLanguage.locale_iso }}
          </span>
        </p>
      </span>
    </template>
    <language-selector-languages-area
      :languages="languages"
      :current-language="currentLanguage"
    />
  </v-menu>
</template>

<script>
import LanguageSelectorLanguagesArea from "./LanguageSelectorLanguagesArea";
import { flagImg } from "../../utils/service/helpers";

export default {
  name: "LanguageSelector",
  components: { LanguageSelectorLanguagesArea },
  computed: {
    languages() {
      return [
        {
          lang: "en",
          locale_iso: "EN",
          translated_url: "/",
          region_name: "United States",
          region_language: "English",
        },
        {
          lang: "lt",
          locale_iso: "LT",
          translated_url: "/lt",
          region_name: "Lietuva",
          region_language: "Lietuvių",
        },
      ];
    },
    currentLang() {
      return this.$route.params.locale || "en";
    },
    currentLanguage() {
      return this.languages.find(({ lang }) => lang === this.currentLang) || {};
    },
  },
  methods: {
    flagImg,
  },
};
</script>

<style lang="scss" scoped>
.language-select {
  &,
  * {
    color: $text-primary;
  }
}
</style>
