<template>
  <div class="language-container">
    <div class="language-area th-py-12 th-px-24">
      <a
        v-for="language in languages"
        :id="'th-language_selector-locale_list-locale_' + language.lang"
        :key="language.lang"
        @click="switchLanguage(language.lang)"
        class="d-flex align-items-center language-link th-py-16 text-nowrap text-decoration-none"
      >
        <img
          :srcset="srcset(language)"
          :src="require(`@/assets/images/languages/${language.lang}.png`)"
          :alt="language.lang"
        />
        <span class="d-inline-flex align-items-center th-ml-8">
          <!--          <span>{{ language.region_name }}</span>-->
          <span>
            {{ language.region_language }}
          </span>
        </span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "LanguageSelectorLanguagesArea",
  props: {
    languages: {
      type: Array,
      default: () => [],
    },
    currentLanguage: [Object, Array],
  },
  methods: {
    srcset(language) {
      let srcset = "";

      try {
        srcset +=
          require(`@/assets/images/languages/${language.lang}.png`) + " 1x,";
      } catch (e) {
        // do nothing
      }

      try {
        srcset +=
          require(`@/assets/images/languages/${language.lang}2x.png`) + " 2x";
      } catch (e) {
        // do nothing
      }

      return srcset;
    },
    switchLanguage(locale) {
      this.$router.replace({
        ...this.$route,
        params: {
          ...this.$route.params,
          locale,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.align-md-baseline {
  @media (min-width: 768px) {
    vertical-align: baseline !important;
  }
}
.height-inherit {
  height: inherit;
}

.align-baseline {
  align-items: baseline;
}

.scale-hover:hover {
  transform: scale3d(1.05, 1.05, 1);
}

.header {
  .search-container {
    display: block;
    float: left;
    padding-left: 0;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
}

.language-container {
  display: flex;
  flex-direction: column;
  background: $white;
  /*position: absolute;
    top: 68px;
    margin-left: -24px;*/

  @include elevation6();

  @include media-breakpoint-down(sm) {
    /*position: fixed;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*margin-left: 0;*/
  }

  .language-area {
    width: 100%;
    display: inline-block;
    border-radius: 6px;

    .language-selector-language {
      margin-bottom: 10px;
      overflow: hidden;
      display: inline-block;

      .disabled-language-link {
        pointer-events: none;
      }
    }
  }
}

.language-link {
  border-bottom: 1px solid $border-color;
  color: $text-primary;
  &:last-child {
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
  }
}
</style>
